    
 .thumb-ui-full-width {
    background-color: #172136;
    width: 100%;
    z-index: 5;
}
.thumb-cricle {
    background-color: transparent;
    border: 2px solid white;
    display: block;
    width: 20px;
    height: 20px;
    z-index: 15;
    position: absolute;
    top: 45%;
    transform: translate(-40% , -50%);
    border-radius: 50%;
    cursor: pointer;
    transition: all linear 0.8s;
}
.volume-cricle {
    left: 50%;
}
.volume-thumb {
    width: 50%;
}
.thumb-wrapper time {
    margin: 0 20px !important;
    color: white;
}

/* 
    =================================================================== Responsive ========================================================================================================
*/
