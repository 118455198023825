@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --dark-color: #171717;
  --light-color: #eaeef6;
  --dark-text: black;
}

body {
  direction: rtl;
  background-color: var(--dark-color);
  color: var(--light-color);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #9e9e9e35;
  width: 8px;
}

.container {
  margin: auto;
}

.active-scale {
  transform: scale(1) !important;
}

.active-border {
  border-bottom: 3px solid #03a9f4;
}

.link-item div {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
  text-transform: capitalize;
}

.link-item a span {
  transition: transform 0.5s;
}

.link-item:last-child span h4 {
  line-height: 1;
}

.link-item:last-child span p {
  font-size: 12px;
}

.link-item i {
  min-width: 20px;
  min-height: 20px;
  margin-right: 20px !important;
}

.thumb-ui,
.thumb-ui-full-width {
  height: 3px;
  display: block;
  background-color: white;
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-40%);
  cursor: pointer;
  z-index: 10;
  transition: all linear 0.8s;
}

.thumb-ui::after {
  content: "";
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: inherit;
}